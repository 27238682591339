<template>
  <a-select :allow-clear="allowClear" :filter-option="false" :options="options" placeholder="请选择中间件类型" :value="value"
    @change="v => $emit('input', v)"></a-select>
</template>

<script>
export default {
  name: 'MiddlewareTypeSelect',
  props: {
    allowClear: { type: Boolean, default: true },
    value: { type: String }
  },
  data () {
    return {
      options: [
        { title: 'Nginx', value: 'nginx' }
        // { title: 'Tomcat', value: 'tomcat' },
        // { title: 'Apache', value: 'apache' }
        // { title: 'IIS', value: 'iis' },
        // { title: 'PHP-FPM', value: 'php_fpm' },
        // { title: 'RabbitMQ', value: 'rabbitmq' }
      ]
    }
  }
}
</script>
