<template>
  <a-modal :confirm-loading="loading" title="批量编辑中间件" :visible="visible" :width="720" @ok="ok" @cancel="reset">
    <a-form-model ref="form" :label-col="{ span: 4, style: { textAlign: 'left' } }" :model="form" :rules="rules"
      :wrapper-col="{ span: 20 }">
      <a-form-model-item label="所属系统" prop="projects">
        <project-select v-model="form.projects" :label-in-value="true" mode="multiple"></project-select>
      </a-form-model-item>
    </a-form-model>
  </a-modal>
</template>

<script>
import { batchUpdateMiddleware } from '@/api/middleware'
import ProjectSelect from '@/components/select/ProjectSelect'

export default {
  name: 'BatchUpdateForm',
  components: { ProjectSelect },
  data () {
    return {
      loading: false,
      visible: false,
      form: {
        projects: []
      },
      rules: {
        projects: [{ message: '请选择系统', required: true, trigger: 'change' }]
      }
    }
  },
  methods: {
    show (ids) {
      this.form.middleware_ids = ids
      this.visible = true
    },
    reset () {
      this.$refs.form.resetFields()
      this.visible = false
    },
    ok () {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.loading = true
          const data = {}
          data.middleware_ids = this.form.middleware_ids
          if (this.form.projects) {
            const arr = []
            this.form.projects.forEach(project => {
              arr.push(project.key)
            })
            data.project_ids = arr
          }
          batchUpdateMiddleware(data).then(res => {
            this.$message.success(res.message)
            this.$emit('ok')
            this.reset()
          }).finally(() => {
            this.loading = false
          })
        }
      })
    }
  }
}
</script>
